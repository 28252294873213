
import { defineComponent, ref, onMounted, watch } from "vue";
import PartnerModal from "@/components/modals/forms/iapartner/IaPartnerModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "IaPartnerList",
  components: {
    PartnerModal,
  },
  setup() {
    const { bus } = useBus();
    const editData = ref(null);
    const iaPartnerList = ref([] as any[]);
    const trash = ref(false);
    const checkedIds = ref<number[]>([]);

    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (data) => {
      editData.value = data;
    };

    const show = (item) => {
      // Add logic for showing item
    };

    const deleteOutput = async (id) => {
      Swal.fire({
        title: trash.value ? "Are you sure you want to restore it?" : "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(apiEndpoint.data().VUE_APP_OUTPUT_DELETE);
            const response = await ApiService.post(
              apiEndpoint.data().VUE_APP_IAPARTNER_DELETE,
              {
                id: id,
              }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting ia partner:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting ia partner",
              type: "error",
            });
          }
        }
      });
    };

    const loadData = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
          {
            with_parent: 1,
            trash: trash.value ? 1 : 0,
          }
        );
        iaPartnerList.value = response.data.data;

        iaPartnerList.value.forEach((item: any) => {
          item.workplan_permission = item.workplan_permission === 1; // Convert to boolean
        });

        checkedIds.value = iaPartnerList.value
            .filter((item: any) => item.workplan_permission === true)
            .map((item: any) => item.id);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadTrash = () => {
      trash.value = !trash.value;
    };

    watch(trash, (newVal) => {
      loadData();
    });

    const toggleAllCheckboxes = (event: Event) => {
      const isChecked = (event.target as HTMLInputElement).checked;
      if (isChecked) {
        checkedIds.value = iaPartnerList.value.map((item: any) => {
          item.workplan_permission = 1; // Update the data model
          return item.id; // Return IDs for API update
        });
      } else {
        checkedIds.value = [];
        iaPartnerList.value.forEach((item: any) => {
          item.workplan_permission = 0; // Update the data model
        });
      }
    };

    const handleCheckboxChange = async (item: any) => {
      const id = item.id;
      // Check if the ID is already in checkedIds.value
      const index = checkedIds.value.indexOf(id);

      if (index === -1) {
        // ID not found in checkedIds.value, add it
        checkedIds.value.push(id);
      } else {
        // ID found in checkedIds.value, remove it
        checkedIds.value.splice(index, 1);
      }
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IAPARTNER_WORKPLAN_PERMISSION,
            {
              ids: checkedIds.value,
            }
        );

        if (response.data.status === "success") {
          ElNotification({
            dangerouslyUseHTMLString: true,
            message: response.data.data,
            type: "success",
          });
          // Optionally reload data after successful update
          // loadData();
        } else {
          ElNotification({
            dangerouslyUseHTMLString: true,
            message: response.data.message,
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error updating workplan permission:", error);
        ElNotification({
          dangerouslyUseHTMLString: true,
          message: "Error updating workplan permission",
          type: "error",
        });
      }
    };

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      loadData();

      bus.on("loadData", async () => {
        loadData();
      });
    });

    return { editData, iaPartnerList, trash, loadTrash, create, edit, show, deleteOutput, toggleAllCheckboxes, handleCheckboxChange };
  },
});
